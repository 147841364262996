import * as React from 'react';
import { IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

function CustomCopyCellRender(props) {
    const { hasFocus, value } = props;
    const buttonElement = React.useRef(null);
    const rippleRef = React.useRef(null);

    React.useLayoutEffect(() => {
        if (hasFocus) {
            const input = buttonElement.current.querySelector('input');
            input?.focus();
        } else if (rippleRef.current) {
            // Only available in @mui/material v5.4.1 or later
            rippleRef.current.stop({});
        }
    }, [hasFocus]);

    return (
        <>
            <IconButton
                aria-label="copy"
                size="small"
                onClick={(event) => {
                    event.stopPropagation();
                    navigator.clipboard.writeText(value?.toString() ?? '');
                }}
                ref={buttonElement}
                touchRippleRef={rippleRef}
                tabIndex={hasFocus ? 0 : -1}
            >
                <ContentCopyIcon fontSize="small" />
            </IconButton>
            <div>{value}</div>
        </>
    );
}

export default CustomCopyCellRender;

