import axios from "axios";

const base = 'environments';

export const EnvironmentsApi = {

    list: async ()=>{
        return await axios.get(`${base}`).then(response => response.data)
    },

    patch: async(environment)=>{
        return await axios.patch(`${base}`,{
            app: environment.application,
            branch: environment.branch,
            action: environment.isOnline ? "OFF" : "ON",
        }).then(response => response.data)
    },

    put: async(payload)=>{
        return await axios.put(`${base}`,payload).then(response => response.data)
    },

    getVariables: async (taskDefinition) => {
        return await axios.get(`secrets/${taskDefinition}`).then(response => response.data)
    },
}

export default EnvironmentsApi;