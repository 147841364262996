import axios from "axios";

const base = 'bc-csv'

export const CsvApi = {
    list: async ()=>{
        return await axios.get(`${base}`).then(response => response.data)
    },
    get: async (fileId)=>{
        return await axios.get(`${base}/${fileId}`).then(response => response.data)
    },
    download: async (environment, object)=>{
        return await axios.post(`${base}/${environment.toLowerCase()}/${object.toLowerCase()}`).then(response => response.data)
    },
}

export default CsvApi;