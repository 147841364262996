import EnvironmentPage from "./pages/environment";
import UsersPage from "./pages/users";
import SalesforceSyncPage from "./pages/salesforce-sync";
import SlfEventsPage from "./pages/salesforce-events";
import BosscatCsvPage from "./pages/bc-csv";
import { getConfig } from './config';

const appConfig = getConfig();

let routes = [
  { name: "Users", element: <UsersPage />, href: "/", current: true },
];

if(appConfig.BRANCH === "main") {
  routes = [
    { name: "Salesforce Sync", element: <SalesforceSyncPage />, href: "/", current: true },
    { name: "Environments", element: <EnvironmentPage />, href: "/environments", current: false },
    { name: "Users", element: <UsersPage />, href: "/users", current: false },
    { name: "Export BC Objects", element: <BosscatCsvPage />, href: "/bc-csv", current: false },
    { name: "Salesforce Events", element: <SlfEventsPage />, href: "/slf-events", current: false },
  ];
}

export default routes;
