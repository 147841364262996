import SlfEvents from "../components/pages/salesforce-events";

const SlfEventsPage = () => {

  return (
    <>      
      <SlfEvents />
    </>
  );
};

export default SlfEventsPage;
