import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getConfig } from '../config';

const AxiosInterceptorContext = React.createContext({ isTokenSet: false });

const setAxiosTokenInterceptor = async (accessToken) => {
    
  axios.interceptors.request.use(async (config) => {
    const appConfig = getConfig();
    config.baseURL = appConfig.API_URL + "punchlist/";

    if (accessToken) {
      if(config.headers) {
        config.headers['Authorization'] = `Bearer ${accessToken}`
      } else {
        config.headers = {
          'Authorization': `Bearer ${accessToken}`
        }
      }
    }
    return config;
  });
};

export const useAxiosInterceptor = () => {
  const context = React.useContext(AxiosInterceptorContext);
  if (context === undefined) {
    throw new Error('useAxiosInterceptor must be used within an AxiosInterceptorProvider');
  }
  return context;
};

export const AxiosInterceptorProvider = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [isTokenSet, setIsTokenSet] = useState(false);

  useEffect(() => {
    const getAccessToken = async () => {
      const accessToken = await getAccessTokenSilently();
      await setAxiosTokenInterceptor(accessToken);
      setIsTokenSet(true);
    };
    getAccessToken();
  }, [getAccessTokenSilently]);

  return (
    <AxiosInterceptorContext.Provider value={{ isTokenSet }}>
      {children}
    </AxiosInterceptorContext.Provider>
  );
};