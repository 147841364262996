import BosscatCsv from "../components/pages/bc-csv";

const BosscatCsvPage = () => {
  
  return (
    <>
      <BosscatCsv />
    </>
  );
};

export default BosscatCsvPage;
