import * as React from "react";
import { ENVIRONMENT_ACTION } from "../../../util/constants";
import EnvironmentsApi from "../../../api/environments-api";
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Skeleton from '@mui/material/Skeleton';
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Stack from '@mui/material/Stack';
import { DEFAULT_ROWS_PER_PAGE } from "../../../util/constants";
import Box from "@mui/material/Box";
import EnvironmentDataGridToolbar from "./environment-data-grid-toolbar";
import CustomDataGridPagination from "../../data-grid/custom-data-grid-pagination";
import { CustomDataGrid } from "../../data-grid/custom-data-grid";
import { getArrayElementByIds } from "../../../util/array";
import TaskVariables from "./task-variables";

const Environment = () => {
  const slots = {
    toolbar: EnvironmentDataGridToolbar,
    pagination: CustomDataGridPagination,
  };

  const defaultMessageText = "Operation Success!";
  const defaultMessageSeverity = "success";

  const [open, setOpen] = React.useState(false);
  const [currentAction, setCurrentAction] = React.useState(
    ENVIRONMENT_ACTION.NONE
  );
  const [stats, setStats] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [selectedItemsLocal, setSelectedItemsLocal] = React.useState([]);
  const [environmentsForCompare, setEnvironmentsForCompare] = React.useState([]);
  const [selectedItemIds, setSelectedItemIds] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [showMessage, setShowMessage] = React.useState(false);
  const [messageSeverity, setMessageSeverity] = React.useState(
    defaultMessageSeverity
  );
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [messageText, setMessageText] = React.useState(defaultMessageText);

  // const [selectedRowEnvironment, setSelectedRowEnvironment] = React.useState({
  //   application: "",
  //   environment: "",
  //   taskDefinition: null,
  // });

  const onVariablesBtnClick = React.useCallback(
    (event, row) => {
      event.stopPropagation();
      if(row.taskDefinition === null){
        setMessageSeverity("error");
        setMessageText("Variables not available for this environment");
        setShowMessage(true);
      }else{
        setEnvironmentsForCompare([row]);
        setOpenDrawer(true);
      }
    },
    []
  );

  const columns = [
    {
      field: "application",
      headerName: "Application",
      width: 230,
    },
    {
      field: "environment",
      headerName: "Environment",
      width: 230,
    },
    {
      field: "desiredSize",
      headerName: "Desired",
      type: "number",
      width: 120,
    },
    {
      field: "minSize",
      headerName: "Min",
      type: "number",
      width: 120,
    },
    {
      field: "maxSize",
      headerName: "Max",
      type: "number",
      width: 120,
    },
    {
      field: "runningCount",
      headerName: "Running",
      type: "number",
      width: 120,
    },
    {
      field: "isOnline",
      headerName: "Status",
      type: "boolean",
      width: 150,
      renderCell: (params) => {
        const label = params.value ? "Online" : "Offline";
        const color = params.value ? "success" : "error";
        return <Chip label={label} color={color} />;
      },
    },
    {
      field: "taskDefinition",
      headerName: "Variables",
      width: 100,
      renderCell: (params) => {
        return <IconButton
          aria-label="Variables"
          color="secondary"
          onClick={(e) => onVariablesBtnClick(e, params.row)}
          >
          <AssignmentIcon />
        </IconButton>;
      },
    },
  ];

  const initialState = {
    pagination: {
      paginationModel: {
        pageSize: DEFAULT_ROWS_PER_PAGE,
      },
    },
  };

  const onTurnOff = React.useCallback(
    (selectedItems) => {
      setCurrentAction(ENVIRONMENT_ACTION.OFF);
      const nonProdEnvIds = selectedItems.filter((itemId)=>{
        const branch = itemId.split(".")[1];
        return branch !== 'master' && branch !== 'main' && branch !== "master" && branch !== "main";
      })
      const elementsToAction = getArrayElementByIds(rows, nonProdEnvIds);
      setSelectedItemsLocal(elementsToAction);
      setOpen(true);
    },
    [rows]
  );

  const onTurnOn = React.useCallback(
    (selectedItems) => {
      setCurrentAction(ENVIRONMENT_ACTION.ON);
      const nonProdEnvIds = selectedItems.filter((itemId)=>{
        const branch = itemId.split(".")[1];
        return branch !== 'master' && branch !== 'main' && branch !== "master" && branch !== "main";
      })
      // Use only the non prod environments.
      const elementsToAction = getArrayElementByIds(rows, nonProdEnvIds);
      setSelectedItemsLocal(elementsToAction);
      setOpen(true);
    },
    [rows]
  );

  const onCompare = React.useCallback(
    (selectedItems) => {
      const elementsToAction = getArrayElementByIds(rows, selectedItems);
      setEnvironmentsForCompare(elementsToAction);
      setOpenDrawer(true);
    },
    [rows]
  );

  const handleBulkAction = async (action, selectedItems) => {
    // console.log(action, selectedItems);
    const payload = {
      action,
      environments: selectedItems.map((e) => {
        return { application: e.application, branch: e.branch };
      }),
    };
    setLoading(true);
    setMessageText(defaultMessageText);
    setMessageSeverity(defaultMessageSeverity);
    EnvironmentsApi.put(payload)
      .then((response) => {
        onRefreshBtnClick();
      })
      .catch((e) => {
        console.log(e);
        setMessageSeverity("error");
        setMessageText(e.message);
      })
      .finally(() => {
        setLoading(false);
        setShowMessage(true);
      });
  };

  const handleOnConfirmAction = () => {
    setOpen(false);
    handleBulkAction(currentAction, selectedItemsLocal);
    handleClose(null, "escapeKeyDown");
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
      setSelectedItemsLocal([]);
      setCurrentAction(ENVIRONMENT_ACTION.NONE);
    }
  };

  const onRefreshBtnClick = () => {
    setLoading(true);
    setMessageSeverity(defaultMessageSeverity);
    EnvironmentsApi.list()
      .then((response) => {
        const initialRows = response.data.map((env) => {
          env["id"] = `${env.application}.${env.environment}`;
          return env;
        });

        setRows(initialRows);
        updateEnvironmentsStats(initialRows);
      })
      .catch(console.error)
      .finally(() => {
        setLoading(false);
      });
  };

  const updateEnvironmentsStats = React.useCallback((newEnvs) => {
    let apps = {};
    newEnvs.forEach((element) => {
      if (apps[element.application]) {
        apps[element.application].push(element.environment);
      } else {
        const firstAppEnvs = [];
        firstAppEnvs.push(element.environment);
        apps[element.application] = firstAppEnvs;
      }
    });
    const newStats = [];
    newStats.push({
      title: "Applications",
      initials: "APL",
      value: Object.keys(apps).length,
      bgColorClass: "bg-purple-600",
    });

    newStats.push({
      title: "Environments",
      initials: "ENV",
      value: newEnvs.length,
      bgColorClass: "bg-yellow-600",
    });
    const onlines = newEnvs.filter((el) => {
      return el.isOnline;
    });
    newStats.push({
      title: "Online",
      initials: "ONL",
      value: onlines.length,
      bgColorClass: "bg-green-700",
    });
    newStats.push({
      title: "Offline",
      initials: "OFF",
      value: newEnvs.length - onlines.length,
      bgColorClass: "bg-red-600",
    });
    setStats(newStats);
  }, []);

  React.useEffect(() => {
    const fetchData = () => {
      setLoading(true);
      setMessageSeverity(defaultMessageSeverity);
      EnvironmentsApi.list()
        .then((response) => {
          // console.log(response);

          const initialRows = response.data.map((env) => {
            env["id"] = `${env.application}.${env.environment}`;
            return env;
          });

          setRows(initialRows);
          updateEnvironmentsStats(initialRows);
        })
        .catch(console.error)
        .finally(() => {
          setLoading(false);
        });
    };
    fetchData();
  }, [updateEnvironmentsStats]);

  const handleCloseMessage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowMessage(false);
  };

  const slotProps = {
    toolbar: {
      onPowerOffHandler: onTurnOff,
      onPowerOnHandler: onTurnOn,
      onRefreshHandler: onRefreshBtnClick,
      onCompareHandler: onCompare,
    },
  };

  const onFilterModelChange = (model, details) => {
    setSelectedItemIds([]);
  };

  const onCloseDrawer = (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpenDrawer(false);
  };

  const onRowSelectionModelChange = React.useCallback(
    (params, event, details) => {
      // Avoid selecting master or main branch
      // const nonProdEnvIds = params.filter((itemId)=>{
      //   const branch = itemId.split(".")[1];
      //   return branch !== 'master' && branch !== 'main' && branch !== "master" && branch !== "main";
      // })
      // setSelectedItemIds(nonProdEnvIds);
      setSelectedItemIds(params);
      // Get the objects from rows
      // const selectedItems = getArrayElementByIds(rows, params);
      // setSelectedItemsLocal(selectedItems);
    },
    []
  );

  if (loading) {
    return (
      <>
        <div className="mb-4 px-4 pb-2 sm:px-6 lg:px-8 ">
          <ul className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6 xl:grid-cols-4 md:grid-cols-4">
            <li className="relative col-span-1 flex rounded-md" >
              <Skeleton height={100} width="100%" />
            </li>
            <li className="relative col-span-1 flex rounded-md" >
              <Skeleton height={100} width="100%" />
            </li>
            <li className="relative col-span-1 flex rounded-md" >
              <Skeleton height={100} width="100%" />
            </li>
            <li className="relative col-span-1 flex rounded-md" >
              <Skeleton height={100} width="100%" />
            </li>
          </ul>
        </div>
        <Stack spacing={1}>
          <Skeleton animation="wave" variant="rectangular" height={80} />
          <Skeleton variant="rectangular" height={50} />
          <Skeleton animation="wave" variant="rectangular" height={50} />
          <Skeleton variant="rectangular" height={50} />
          <Skeleton animation="wave" variant="rectangular" height={50} />
          <Skeleton variant="rectangular" height={50} />
          <Skeleton animation="wave" variant="rectangular" height={50} />
          <Skeleton variant="rectangular" height={50} />
          <Skeleton animation="wave" variant="rectangular" height={50} />
          <Skeleton variant="rectangular" height={50} />
        </Stack>
      </>
    );
  }

  return (
    <>
      <Snackbar
        open={showMessage}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleCloseMessage}
      >
        <Alert
          severity={messageSeverity}
          sx={{ width: "100%" }}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseMessage}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          {messageText}
        </Alert>
      </Snackbar>
      <div className="mb-4 px-4 pb-2 sm:px-6 lg:px-8">
        <ul className="mt-3 grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6 xl:grid-cols-4 md:grid-cols-4">
          {stats.map((stat, index) => (
            <li
              key={index}
              className="relative col-span-1 flex rounded-md shadow-sm"
            >
              <div
                className={
                  stat.bgColorClass +
                  " flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white"
                }
              >
                {stat.initials}
              </div>
              <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-t border-r border-b border-gray-200 bg-white">
                <div className="flex-1 truncate px-4 py-2 text-sm font-medium text-gray-900">
                  {stat.title}

                  <p className="text-gray-500">{stat.value}</p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ height: 632, width: "100%" }} className="bg-white">
            <CustomDataGrid
              rows={rows}
              columns={columns}
              initialState={initialState}
              pageSizeOptions={[DEFAULT_ROWS_PER_PAGE]}
              checkboxSelection
              rowSelectionModel={selectedItemIds}
              onRowSelectionModelChange={onRowSelectionModelChange}
              onFilterModelChange={onFilterModelChange}
              slots={slots}
              slotProps={slotProps}
              disableMultipleColumnsFiltering={false}
              keepNonExistentRowsSelected={false}
            />
          </Box>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {currentAction === ENVIRONMENT_ACTION.OFF ? "Turn Off" : "Turn On"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to
            {currentAction === ENVIRONMENT_ACTION.OFF
              ? " Turn off "
              : " Turn on "}
            the selected environments ?<br></br>
            <br></br>
            This operation may take several minutes to complete.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              handleClose(e, "escapeKeyDown");
            }}
          >
            Cancel
          </Button>
          <Button onClick={handleOnConfirmAction} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <TaskVariables open={openDrawer} onClose={onCloseDrawer} environments={environmentsForCompare} />
    </>
  );
};

export default Environment;
