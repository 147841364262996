import './App.css';
import { Routes, Route } from 'react-router-dom'; 
import Layout from './components/layout'
import routes from './routes';
import { useAuth } from './auth/CommonAuthProvider';
import Loading from './components/loading';

function App() {
  const { isTokenSet, isAuthenticated } = useAuth();
  
  if(isAuthenticated && !isTokenSet) {
    return <Loading />
  }

  return (
    <div>
      <Routes>
        <Route path="/" element={<Layout />} >
          {routes.map((item) => (
            <Route key={item.name} path={item.href} element={item.element} >

            </Route>
          ))}
        </Route>
        
        
      </Routes>
    </div>
  );
}

export default App;
