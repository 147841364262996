import Environment from "../components/pages/environment/environment";

const EnvironmentPage = () => {

  return (
    <>      
      <Environment />
    </>
  );
};

export default EnvironmentPage;
