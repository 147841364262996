import * as React from "react";
import PropTypes from "prop-types";
import SalesforceApi from "../../api/salesforce-api";
import Drawer from '@mui/material/Drawer';
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Refresh from "@mui/icons-material/Refresh";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { DEFAULT_ROWS_PER_PAGE } from "../../util/constants";
import Box from "@mui/material/Box";
import CustomDataGridPagination from "../data-grid/custom-data-grid-pagination";
import { CustomDataGrid } from "../data-grid/custom-data-grid";
import {
  GridFilterPanel
} from "@mui/x-data-grid";
import { GridToolbarExport } from '@mui/x-data-grid';
import SwitchesGroup from "../switch-group";
import { JsonViewer } from "@textea/json-viewer";

const CustomDataGridToolbar = (props) => {
  const { 
    onRefreshHandler, 
    environments, 
    selectedEnvironment, 
    onEnvironmentChange, 
    intervals,
    selectedInterval,
    onIntervalChangeHandler, 
  } = props;

  const formatValue = (value) => {
    return value.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
  }

  const onChangeIntervalHandler = (event) => {
    onIntervalChangeHandler(event.target.value);
  }


  return (
    <>
      <Grid container>
        <Grid item xs={6} md={6} className="text-center">

          <GridFilterPanel className="mt-5 pt-5" />
        </Grid>

        <Grid item xs={6} md={6} className="text-right">
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            className="p-2 m-2"
          >
            <Button
              variant="outlined"
              startIcon={<Refresh />}
              onClick={onRefreshHandler}
            >
              Refresh
            </Button>
            <FormControl size="small">
              <InputLabel id="interval-select-label">Interval</InputLabel>
              <Select
                labelId="interval-select-label"
                id="interval-select"
                value={selectedInterval}
                label="Interval"
                onChange={onChangeIntervalHandler}
              >
                {intervals.map((item, index) => {
                  return (
                    <MenuItem
                      value={item}
                      key={index}
                    >{formatValue(item)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Stack>
          <SwitchesGroup options={environments} selectedOption={selectedEnvironment} onChange={onEnvironmentChange} />
          <Divider />
          <GridToolbarExport />
        </Grid>
      </Grid>
      <Divider />
    </>
  );
}

CustomDataGridToolbar.propTypes = {
  onRefreshHandler: PropTypes.func,
  environments: PropTypes.array,
  selectedEnvironment: PropTypes.string, 
  onEnvironmentChange: PropTypes.func,
  intervals: PropTypes.array,
  selectedInterval: PropTypes.string, 
  onIntervalChangeHandler: PropTypes.func,
};

const RowContentDetail = ({ row })=>{
  return (
    <>
      <JsonViewer value={row} rootName="Event"  />
    </>
  );
}

RowContentDetail.propTypes = {
  row: PropTypes.object,
};

const SlfEvents = () => {
  const slots = {
    toolbar: CustomDataGridToolbar,
    pagination: CustomDataGridPagination,
  };

  const defaultMessageText = "Operation Success!";
  const defaultMessageSeverity = "success";
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [showMessage, setShowMessage] = React.useState(false);
  const [messageSeverity, setMessageSeverity] = React.useState(defaultMessageSeverity);
  const isFirstRender = React.useRef(true);
  const [messageText,] = React.useState(defaultMessageText);
  const intervals = React.useMemo(() => {
    return ["LAST_HOUR", "LAST_DAY", "LAST_WEEK", "LAST_MONTH", "ARCHIVE"];
  }, []);
  const environments = React.useMemo(() => {
    return ['DEV', 'UAT', 'PROD'];
  }, []);

  const [interval, setInterval] = React.useState("");
  const [selectedEnvironment, setSelectedEnvironment] = React.useState("");
  const [selectedItem, setSelectedItem] = React.useState({});

  const columns = [
    {
      field: "PK",
      headerName: "Interval",
      width: 120,
    },
    {
      field: "CreatedOn",
      headerName: "Created On",
      width: 170,
      type: 'dateTime',
      valueGetter: ({ value }) => value && new Date(value * 1000),
    },
    {
      field: "ExpirationTime",
      headerName: "Interval Ends On",
      width: 170,
      type: 'dateTime',
      valueGetter: ({ value }) => value && new Date(value * 1000),
    },
    {
      field: "MessageId",
      headerName: "Message Id",
      width: 300,
    },
    {
      field: "Body",
      headerName: "Body",
      width: 320,
    },
    {
      field: "messageAttributes",
      headerName: "Message Attributes",
      width: 320,
    },

  ];

  const initialState = {
    pagination: {
      paginationModel: {
        pageSize: DEFAULT_ROWS_PER_PAGE,
      },
    },
  };

  const onRefreshBtnClick = () => {
    fetchData(selectedEnvironment, interval);
  };

  const fetchData = React.useCallback((environment, inter) => {
    setLoading(true);
      setMessageSeverity(defaultMessageSeverity);
      SalesforceApi.events(environment, inter)
        .then((response) => {
          // console.log(response);
          const initialRows = response.data.map((env) => {
            env["id"] = env.MessageId;
            env["messageAttributes"] = JSON.stringify(env.MessageAttributes);
            return env;
          });
          setRows(initialRows);
        })
        .catch(console.error)
        .finally(() => {
          setLoading(false);
        });
  }, []);

  React.useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      const localEnv = environments[0];
      const localInterval = intervals[0];
      setSelectedEnvironment(localEnv);
      setInterval(localInterval);
      fetchData(localEnv, localInterval);
    }
  }, [environments, intervals, fetchData]);

  const handleCloseMessage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowMessage(false);
  };

  const onIntervalChange = (value) => {
    console.log(value);
    setInterval(value)
    fetchData(selectedEnvironment, value);
  }

  const onEnvironmentChange = (value) => {
    setSelectedEnvironment(value)
    fetchData(value, interval);
  }

  const slotProps = {
    toolbar: {
      onRefreshHandler: onRefreshBtnClick,
      environments: environments,
      selectedEnvironment: selectedEnvironment,
      onEnvironmentChange: onEnvironmentChange,
      intervals: intervals,
      selectedInterval: interval,
      onIntervalChangeHandler: onIntervalChange,
    },
  };

  const setSelectedRow = (row) => {
    const newRow = {...row}
    delete newRow.messageAttributes;
    delete newRow.ExpirationTime;
    delete newRow.PK;
    // delete newRow.CreatedOn;
    delete newRow.ReceiptHandle;
    setSelectedItem(newRow);
  }

  const onRowClick = (selectedRowsIds) => {
    // Search in rows the element with the id rowId
    const row = rows.find((row) => row.id === selectedRowsIds[0]);
    if(row){
      setSelectedRow(row);
      setOpenDrawer(true);
    }
  }

  const onCloseDrawer = (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpenDrawer(false);
  }

  if (loading) {
    return (
      <>
        <Stack spacing={1}>
          <Skeleton animation="wave" variant="rectangular" height={80} />
          <Skeleton variant="rectangular" height={50} />
          <Skeleton animation="wave" variant="rectangular" height={50} />
          <Skeleton variant="rectangular" height={50} />
          <Skeleton animation="wave" variant="rectangular" height={50} />
          <Skeleton variant="rectangular" height={50} />
          <Skeleton animation="wave" variant="rectangular" height={50} />
          <Skeleton variant="rectangular" height={50} />
          <Skeleton animation="wave" variant="rectangular" height={50} />
          <Skeleton variant="rectangular" height={50} />
        </Stack>
      </>
    );
  }

  return (
    <>
      <Snackbar
        open={showMessage}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleCloseMessage}
      >
        <Alert
          severity={messageSeverity}
          sx={{ width: "100%" }}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseMessage}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          {messageText}
        </Alert>
      </Snackbar>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ height: 800, width: "100%" }} className="bg-white">
            <CustomDataGrid
              rows={rows}
              columns={columns}
              initialState={initialState}
              pageSizeOptions={[DEFAULT_ROWS_PER_PAGE]}
              slots={slots}
              slotProps={slotProps}
              disableMultipleColumnsFiltering={false}
              hideFooterSelectedRowCount={true}
              onRowSelectionModelChange={onRowClick}
            />
          </Box>
        </Grid>
      </Grid>
      <React.Fragment>
          <Drawer
            anchor={'bottom'}
            open={openDrawer}
            onClose={onCloseDrawer}
          >
            <RowContentDetail row={selectedItem} />
          </Drawer>
        </React.Fragment>
    </>
  );
};

export default SlfEvents;
