import React from 'react';
import Drawer from '@mui/material/Drawer';
import Box from "@mui/material/Box";
import CustomDataGridPagination from "../../data-grid/custom-data-grid-pagination";
import { CustomDataGrid } from "../../data-grid/custom-data-grid";
import CustomCopyCellRender from "../../data-grid/custom-data-grid-copy-cell";
import PropTypes from "prop-types";
import EnvironmentsApi from "../../../api/environments-api";
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

function CustomToolbar(props) {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

const TaskVariables = ({ open, onClose, environments }) => {
    const [loading, setLoading] = React.useState(false);
    const [rows, setRows] = React.useState([]);
    const [columns, setColumns] = React.useState([{
        field: "name",
        headerName: "Name",
        flex: 1,
        minWidth: 150,
        hideable: false,
        renderCell: CustomCopyCellRender,
    }]);
    const slots = {
        pagination: CustomDataGridPagination,
        toolbar: CustomToolbar,
    };
    const slotProps = {
        toolbar: { printOptions: { disableToolbarButton: true } }
    };
    const rowsPerpage = React.useMemo(() => {
        return 50;
    }, []);
    const initialState = React.useMemo(() => {
        return {
            pagination: {
                paginationModel: {
                    pageSize: rowsPerpage,
                },
            },
            sorting: {
                sortModel: [{ field: 'name', sort: 'asc' }],
            },
        };
    }, [rowsPerpage]);

    React.useEffect(() => {
        const _envs = {};
        let _rows = [];
        let _columns = [{
            field: "name",
            headerName: "Name",
            flex: 1,
            minWidth: 150,
            hideable: false,
            renderCell: CustomCopyCellRender,
        }];
        const fetchData = (row) => {
            setLoading(true);
            EnvironmentsApi.getVariables(row.taskDefinition)
                .then((response) => {  
                    const variables = response.data.map((env) => {
                        env["id"] = env.name;
                        return env;
                    });

                    const newColumnFieldName = `column-${_columns.length}`;
                    _envs[newColumnFieldName] = "";
                    const newColumnHeaderName = `${row.application} - ${row.environment}`;
                    const localColumns = [..._columns];
                    localColumns.push({
                        field: newColumnFieldName,
                        headerName: newColumnHeaderName,
                        flex: 1,
                        minWidth: 150,
                        hideable: false,
                        renderCell: CustomCopyCellRender,
                    });
                    _columns = localColumns;
                    // Combine rows
                    const combinedMap = {};

                    _rows.forEach((localRow) => {
                        const { id } = localRow;
                        // Validate if localRow.id exists in variables
                        const variable = variables.find((variable) => variable.id === id);
                        const item = { ...localRow };
                        if (variable === undefined) {
                            item[newColumnFieldName] = "";
                        } else {
                            item[newColumnFieldName] = variable.value;
                        }
                        combinedMap[id] = item;
                    });

                    variables.forEach((variable) => {
                        const { id } = variable;
                        if (combinedMap[id]) {
                            const item = combinedMap[id];
                            item[newColumnFieldName] = variable.value;
                            combinedMap[id] = item;
                        } else {
                            const item = { ..._envs };//All properties of previous rows
                            item["id"] = id;
                            item["name"] = variable.name;
                            item[newColumnFieldName] = variable.value;
                            combinedMap[id] = item;
                        }
                    });

                    // Update columns and rows
                    setColumns(localColumns);
                    _rows = Object.values(combinedMap);
                    setRows(_rows);
                })
                .catch(console.error)
                .finally(() => {
                    setLoading(false);
                });
        };
        if (Array.isArray(environments)) {
            environments.forEach((row) => {
                fetchData(row);
            });
        }
    }, [environments]);

    return (
        <React.Fragment>
            <Drawer
                anchor={'bottom'}
                open={open}
                onClose={onClose}
            >
                <Box sx={{ height: 500, width: "100%" }} className="bg-white">
                    <CustomDataGrid
                        rows={rows}
                        columns={columns}
                        // autoHeight={true}
                        loading={loading}
                        initialState={initialState}
                        pageSizeOptions={[rowsPerpage]}
                        slots={slots}
                        slotProps={slotProps}
                        disableMultipleColumnsFiltering={false}
                        disableRowSelectionOnClick={true}
                        hideFooterSelectedRowCount={true}
                        keepNonExistentRowsSelected={false}
                    />
                </Box>
            </Drawer>
        </React.Fragment>
    );
};


TaskVariables.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    environments: PropTypes.arrayOf(PropTypes.object),
};

export default TaskVariables;