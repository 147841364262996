export const ENVIRONMENT_ACTION = {
  ON: "ON",
  OFF: "OFF",
  NONE: "NONE",
};

export const DEFAULT_ORDER = "asc";
export const DEFAULT_ROWS_PER_PAGE = 10;

export const STRING_OPERATOR = {
  CONTAINS: 'CONTAINS',
  DISTINCT: 'DISTINCT',
  EQUAL: 'EQUAL',
  START_WITH: 'START_WITH',
  END_WITH: 'END_WITH',
  EMPTY: 'EMPTY',
  NO_EMPTY: 'NO_EMPTY',
  ANY_OF: 'ANY_OF',
}

export const NUMBER_OPERATOR = {
  EQUAL: 'EQUAL',
  DISTINCT: 'DISTINCT',
  GREATER_THAN: 'GREATER_THAN',
  GREATER_EQUAL_THAN: 'GREATER_EQUAL_THAN',
  LESS_THAN: 'LESS_THAN',
  LESS_EQUAL_THAN: 'LESS_EQUAL_THAN',
}

export const TYPE_OPERATOR = {
  STRING: 'string',
  NUMBER: 'number'
}

export const OPERATORS = {
  string: [
      { id: STRING_OPERATOR.CONTAINS, label: 'Contains' },
      { id: STRING_OPERATOR.EQUAL, label: 'Equal' },
      { id: STRING_OPERATOR.DISTINCT, label: 'Distinct' },
      { id: STRING_OPERATOR.START_WITH, label: 'Start With' },
      { id: STRING_OPERATOR.END_WITH, label: 'End With' },
      { id: STRING_OPERATOR.EMPTY, label: 'Is Empty' },
      { id: STRING_OPERATOR.NO_EMPTY, label: 'Is Not Empty' },
      { id: STRING_OPERATOR.ANY_OF, label: 'Any of' },
    ],
  number: [
      { id: NUMBER_OPERATOR.EQUAL, label: '=' },
      { id: NUMBER_OPERATOR.DISTINCT, label: '!=' },
      { id: NUMBER_OPERATOR.GREATER_THAN, label: '>' },
      { id: NUMBER_OPERATOR.GREATER_EQUAL_THAN, label: '>=' },
      { id: NUMBER_OPERATOR.LESS_THAN, label: '<' },
      { id: NUMBER_OPERATOR.LESS_EQUAL_THAN, label: '<=' },
    ]
}

export function getOperatorById(idOperator){
  const keys = Object.keys(OPERATORS);
  let result = null
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const filtered = OPERATORS[key].filter((op)=>{
        return idOperator === op.id
    })
    if(filtered.length>0){
      result = Object.assign({type: key},filtered[0]);
      break;
    }
  }
  return result;
}
