import { useState } from "react";
import PropTypes from "prop-types";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { Grid, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SalesforceApi from "../../api/salesforce-api";
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import SwitchesGroup from "../switch-group";

function RowRadioButtonsGroup({ elements, onChange, label }) {

  function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const [selectedValue, setSelectedValue] = useState(elements.length > 0 ? elements[0] : "");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    onChange(event.target.value);
  };

  return (
    <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label">{label}</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >

        {elements.map((el, index) => (
          <FormControlLabel
            key={index}
            checked={selectedValue === el}
            value={el}
            control={<Radio />}
            onChange={handleChange}
            label={capitalize(el)}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

RowRadioButtonsGroup.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
};

RowRadioButtonsGroup.defaultProps = {
  label: 'Object'
};

const Salesforce = () => {
  const defaultMessageText = "Operation Success!"
  const defaultMessageSeverity = "success"
  const [loading, setLoading] = useState(false);
  const [objectId, setObjectId] = useState("");
  const [error, setError] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [messageSeverity, setMessageSeverity] = useState(defaultMessageSeverity);
  const [messageText, setMessageText] = useState(defaultMessageText);

  const objectTypeList = ["vendors", "orders", "notes", "jobs", "items", "estimates", "comments", "clients"];
  const environmentList = ['DEV', 'UAT', 'PROD'];

  const [objectType, setObjectType] = useState(objectTypeList[0]);
  const [selectedEnvironment, setSelectedEnvironment] = useState(environmentList[0]);

  const onTextFieldChange = (value) => {
    setError(false)
    setObjectId(value)
  }

  const syncObject = () => {
    if (objectId) {
      setLoading(true);
      setMessageText(defaultMessageText)
      setMessageSeverity(defaultMessageSeverity)
      SalesforceApi.sync(selectedEnvironment, objectType, objectId).then((response) => {
        console.log(response)
      }).catch((e) => {
        console.log(e)
        setMessageSeverity("error")
        setMessageText(e.message)
      }).finally(() => {
        setLoading(false)
        setShowMessage(true)
      })
    } else {
      setError(true)
    }
  }

  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowMessage(false);
  };

  const handleRadioChange = (value) => {
    setObjectType(value)
  }

  const singularObjectType = (value) => {
    return value.substring(0, value.length - 1)
  }

  return (
    <div className="flex-1">
      <Snackbar
        open={showMessage}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
        onClose={handleCloseMessage}
      >
        <Alert
          severity={messageSeverity}
          sx={{ width: '100%' }}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseMessage}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          {messageText}
        </Alert>
      </Snackbar>
      <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
        <div className="min-w-0 flex-1">
          <div className="rounded-md bg-blue-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <InformationCircleIcon
                  className="h-5 w-5 text-blue-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium">Instructions:</h3>
                <div className="mt-2 text-sm">
                  <ul className="list-disc space-y-1 pl-5">
                    <li>Select the environment to sync</li>
                    <li>Select the object to sync</li>
                    <li>Enter the object ID.</li>
                    <li>
                      Click the SYNC button to sync the information with salesforce.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 px-4 sm:px-6 lg:px-8">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <SwitchesGroup options={environmentList} selectedOption={selectedEnvironment} onChange={setSelectedEnvironment} />
          </Grid>
          <Grid item xs={12} md={12}>
            <RowRadioButtonsGroup
              elements={objectTypeList}
              onChange={handleRadioChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              type="search"
              error={error}
              id="outlined-basic"
              label="Object ID"
              variant="outlined"
              fullWidth={true}
              onChange={(e) => onTextFieldChange(e.target.value)}
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <div className="mt-1 pt-1">
              <LoadingButton
                loading={loading}
                variant="contained"
                startIcon={<CloudSyncIcon />}
                onClick={syncObject}
              >
                Sync {singularObjectType(objectType)}
              </LoadingButton>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Salesforce;
