import React from "react";
import PropTypes from "prop-types";

import {
  Grid,
  Button,
  Typography,
  Badge,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Stack,
} from "@mui/material";

import TouchApp from "@mui/icons-material/TouchApp";
import ListItemIcon from "@mui/material/ListItemIcon";
import PowerOff from "@mui/icons-material/PowerOff";
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import Power from "@mui/icons-material/Power";
import Refresh from "@mui/icons-material/Refresh";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import {
  GridFilterPanel,
  useGridApiContext,
  useGridApiEventHandler,
} from "@mui/x-data-grid";

function BulkActions(props) {
  const { onPowerOffHandler, onPowerOnHandler, onCompareHandler } = props;
  const apiRef = useGridApiContext();

  const prefixId = Math.floor(Math.random() * 100);

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedItems, setSelectedItems] = React.useState([]);

  // onRowSelectionModelChange

  const rowSelectionChange = (
    params, // GridRowSelectionModel
    event, // MuiEvent<{}>
    details // GridCallbackDetails
  ) => {
    setSelectedItems(params);
    apiRef.current.publishEvent(
      "onCustomRowSelectionChange",
      params,
      event,
      details
    );
  };

  // Imperative subscription
  // apiRef.current.subscribeEvent(
  //   'rowSelectionChange',
  //   rowSelectionChange,
  // );

  // Hook subscription (only available inside the scope of the grid)
  useGridApiEventHandler(apiRef, "rowSelectionChange", rowSelectionChange);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  const onPowerOn = (event) => {
    if (onPowerOnHandler) {
      onPowerOnHandler(selectedItems);
    }
  };

  const onPowerOff = (event) => {
    if (onPowerOffHandler) {
      onPowerOffHandler(selectedItems);
    }
  };

  const onCompare = (event) => {
    if (onCompareHandler) {
      onCompareHandler(selectedItems);
    }
  };

  return (
    <div className="pr-2">
      <Button
        variant="contained"
        size="large"
        disabled={selectedItems.length === 0}
        ref={anchorRef}
        id={`${prefixId}-composition-button`}
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        startIcon={
          <Badge color="secondary" badgeContent={selectedItems.length}>
            <TouchApp />
          </Badge>
        }
        endIcon={<KeyboardArrowDownIcon />}
        sx={{ width: 150 }}
      >
        Actions
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        sx={{ width: 150, zIndex: 10 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "right top" : "right bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  className="bg-gray-300 "
                  autoFocusItem={open}
                  id={`${prefixId}-composition-menu`}
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                  onClick={handleClose}
                >
                  <MenuItem onClick={onCompare}>
                    <ListItemIcon>
                      <CompareArrowsIcon />
                    </ListItemIcon>
                    <Typography variant="h6">Compare</Typography>
                  </MenuItem>
                  <MenuItem onClick={onPowerOff}>
                    <ListItemIcon>
                      <PowerOff />
                    </ListItemIcon>
                    <Typography variant="h6">PowerOff</Typography>
                  </MenuItem>
                  <MenuItem onClick={onPowerOn}>
                    <ListItemIcon>
                      <Power />
                    </ListItemIcon>
                    <Typography variant="h6">PowerOn</Typography>
                  </MenuItem>
                  {/* {items.map((item, index) => {
                    return (
                      <MenuItem
                        onClick={(e) => {
                          item.callback(selectedItems);
                        }}
                        key={index}
                      >
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <Typography variant="h6">{item.label}</Typography>
                      </MenuItem>
                    );
                  })} */}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

BulkActions.propTypes = {
  onPowerOffHandler: PropTypes.func,
  onPowerOnHandler: PropTypes.func,
  onCompareHandler: PropTypes.func,
};

export default function EnvironmentDataGridToolbar(props) {
  const { onPowerOffHandler, onPowerOnHandler, onRefreshHandler, onCompareHandler } = props;

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6} className="text-center">
          <GridFilterPanel />
        </Grid>
        <Grid item xs={12} md={6} className="text-right">
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            className="p-2 m-2"
          >
            <Button
              variant="outlined"
              startIcon={<Refresh />}
              onClick={onRefreshHandler}
            >
              Refresh
            </Button>
            <BulkActions
              onPowerOffHandler={onPowerOffHandler}
              onPowerOnHandler={onPowerOnHandler}
              onCompareHandler={onCompareHandler}
            />
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}

EnvironmentDataGridToolbar.propTypes = {
  onPowerOffHandler: PropTypes.func,
  onPowerOnHandler: PropTypes.func,
  onRefreshHandler: PropTypes.func,
  onCompareHandler: PropTypes.func,
};
