import * as React from 'react';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import PropTypes from "prop-types";

export default function SwitchesGroup({ options, selectedOption, legend='Environment', onChange }) {

  const handleChange = (event) => {
    if(onChange && event.target.name !== selectedOption) onChange(event.target.name);
  };

  return (
    <FormControl component="fieldset" variant="standard">
      <FormLabel component="legend" className='text-center'>{legend}</FormLabel>
      <FormGroup row={true}>
        {options.map((option) => (
          <FormControlLabel
            key={option}
            control={
              <Switch
                checked={selectedOption === option}
                onChange={handleChange}
                name={option}
              />
            }
            label={option}
            labelPlacement="bottom"
          />
        ))}
      </FormGroup>
    </FormControl>
  );
}

SwitchesGroup.propTypes = {
    options: PropTypes.array.isRequired,
    legend: PropTypes.string,
    onChange: PropTypes.func,
};