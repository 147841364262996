import { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Grid, Box, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CsvApi from "../../api/csv-api";
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import SwitchesGroup from "../switch-group";
import { CustomDataGrid } from "../data-grid/custom-data-grid";
import CustomToolbar from "../data-grid/custom-data-grid-toolbar";
import CustomDataGridPagination from "../data-grid/custom-data-grid-pagination";

function RowRadioButtonsGroup({ elements, onChange, label }) {

  function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const [selectedValue, setSelectedValue] = useState(elements.length > 0 ? elements[0] : "");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    onChange(event.target.value);
  };

  return (
    <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label">{label}</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >

        {elements.map((el, index) => (
          <FormControlLabel
            key={index}
            checked={selectedValue === el}
            value={el}
            control={<Radio />}
            onChange={handleChange}
            label={capitalize(el)}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

RowRadioButtonsGroup.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
};

RowRadioButtonsGroup.defaultProps = {
  label: 'Object'
};

const BosscatCsv = () => {
  const defaultMessageText = "Operation Success!"
  const defaultMessageSeverity = "success"
  const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [messageSeverity, setMessageSeverity] = useState(defaultMessageSeverity);
  const [messageText, setMessageText] = useState(defaultMessageText);
  const [rows, setRows] = useState([]);

  const objectTypeList = useMemo(() => {
    return [
       "orders", 
      //  "notes", 
      //  "jobs", 
      //  "items",
      "estimates",
      "estimates-contacts",
      "contacts",
      "vendors",
    ];
  }, []);

  const environmentList = useMemo(() => {
    return ['PROD','DEV'];
  }, []);

  const columns = useMemo(() => {
    return [
      {
        field: "date",
        headerName: "Date",
        type: "dateTime",
        flex: 1,
        minWidth: 100,
        valueGetter: (params) => {
          if (!params.value) {
            return null;
          }
          const date = new Date();
          date.setTime(params.value * 1000);
          return date;
        },
      },
      {
        field: "environment",
        headerName: "Environment",
        flex: 1,
        minWidth: 100,
      },
      {
        field: "objectType",
        headerName: "Object Type",
        flex: 1,
        minWidth: 100,
      },
      {
        field: "fileStatus",
        headerName: "Status",
        flex: 1,
        minWidth: 100,
      },
      {
        field: "user",
        headerName: "User",
        flex: 1,
        minWidth: 100,
      },
      {
        field: "fileUrl",
        headerName: "Download",
        width: 100,
        filterable: false,
        renderCell: (params) => {
          return <IconButton
            aria-label="Download"
            color="secondary"
            href={params.value}
            target="_blank"
            disabled={params.row.fileStatus !== "completed"}
          >
            <AssignmentIcon />
          </IconButton>;
        },
      },
    ];
  }, []);

  const [objectType, setObjectType] = useState(objectTypeList[0]);
  const [selectedEnvironment, setSelectedEnvironment] = useState(environmentList[0]);

  // sleep time expects milliseconds
  const sleep = async (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  const downloadObjects = async () => {
    setLoading(true);
    setMessageText(defaultMessageText)
    setMessageSeverity(defaultMessageSeverity)
    try {
      const response = await CsvApi.download(selectedEnvironment, objectType);
      if (response.success) {
        const previewsRows = [...rows];
        const newRow = {...response.data}
        newRow["id"] = newRow.fileId;
        previewsRows.push(newRow)
        setRows(previewsRows)
        let downloaded = false;
        for (let i = 0; i < 3; i++) {
          await sleep(5000);
          const responseStatus = await CsvApi.get(response.data.fileId);
          if (responseStatus.success) {
            if (responseStatus.data.fileStatus === "completed") {
              downloaded = true;
              setMessageText(defaultMessageText)
              setMessageSeverity(defaultMessageSeverity)
              window.open(responseStatus.data.fileUrl, '_blank');
              break;
            } else if (responseStatus.data.fileStatus === "failed") {
              throw new Error("Was not possible to download the file. Contact the administrator.")
            }
          } else {
            throw new Error(responseStatus.message)
          }
        }
        if(!downloaded){
          throw new Error("Was not possible to download the file. Contact the administrator.")
        }
      }
    } catch (error) {
      console.log(error)
      setMessageSeverity("error")
      setMessageText(error.message)
    } finally {
      setLoading(false)
      setShowMessage(true)
    }
  }

  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowMessage(false);
  };

  const handleRadioChange = (value) => {
    setObjectType(value)
  }

  const slots = {
    pagination: CustomDataGridPagination,
    toolbar: CustomToolbar,
  };
  const slotProps = {
    toolbar: { printOptions: { disableToolbarButton: true } }
  };
  const rowsPerpage = useMemo(() => {
    return 20;
  }, []);
  const initialState = useMemo(() => {
    return {
      pagination: {
        paginationModel: {
          pageSize: rowsPerpage,
        },
      },
      sorting: {
        sortModel: [{ field: 'date', sort: 'desc' }],
      },
    };
  }, [rowsPerpage]);

  useEffect(() => {
    const fetchData = () => {
      setLoading(true);
      CsvApi.list()
        .then((response) => {
          // console.log(response);

          const initialRows = response.data.map((item) => {
            item["id"] = item.fileId;
            return item;
          });

          setRows(initialRows);
        })
        .catch(console.error)
        .finally(() => {
          setLoading(false);
        });
    };
    fetchData();
  }, []);

  return (
    <div className="flex-1">
      <Snackbar
        open={showMessage}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
        onClose={handleCloseMessage}
      >
        <Alert
          severity={messageSeverity}
          sx={{ width: '100%' }}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseMessage}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          {messageText}
        </Alert>
      </Snackbar>

      <div className="mt-6 px-4 sm:px-6 lg:px-8">
        <Grid container spacing={2}>

          <Grid item xs={12} md={12}>
            <RowRadioButtonsGroup
              elements={objectTypeList}
              onChange={handleRadioChange}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <SwitchesGroup options={environmentList} selectedOption={selectedEnvironment} onChange={setSelectedEnvironment} />
          </Grid>
          <Grid item xs={6} md={6}>
            <div className="mt-1 pt-1">
              <LoadingButton
                loading={loading}
                variant="contained"
                startIcon={<CloudSyncIcon />}
                onClick={downloadObjects}
              >
                Download {objectType}
              </LoadingButton>
            </div>
          </Grid>
        </Grid>

        <Box sx={{ height: 500, width: "100%" }} className="mt-10">
          <Typography className="text-center" variant="h6">Latests requests</Typography>
          <CustomDataGrid
            className="bg-white"
            rows={rows}
            columns={columns}
            // autoHeight={true}
            loading={loading}
            initialState={initialState}
            pageSizeOptions={[rowsPerpage]}
            slots={slots}
            slotProps={slotProps}
            disableMultipleColumnsFiltering={false}
            disableRowSelectionOnClick={true}
            hideFooterSelectedRowCount={true}
            keepNonExistentRowsSelected={false}
          />
        </Box>
      </div>
    </div>
  );
};

export default BosscatCsv;
