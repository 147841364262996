import axios from "axios";

const base = 'salesforce'

export const SalesforceApi = {
    sync: async (environment, object, id)=>{
        return await axios.post(`${base}/${environment.toLowerCase()}/${object}/${id}`).then(response => response.data)
    },
    events: async (environment, interval)=>{
        return await axios.get(`${base}/${environment.toLowerCase()}/${interval.toLowerCase()}`).then(response => response.data)
    },
}

export default SalesforceApi;