import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { getConfig } from '../config';
import { AxiosInterceptorProvider } from './AxiosInterceptorProvider';
import { CommonAuthProvider, useAuth } from './CommonAuthProvider';

const Auth0ProviderWithHistory = ({ children }) => {

  const config = getConfig();
  const navigateTo = useNavigate();
  
  const onRedirectCallback = (appState) => {
    navigateTo((appState && appState.returnTo) ? appState.returnTo : "/");
  };

  const providerConfig = {
    domain: config.AUTH0_DOMAIN,
    clientId: config.AUTH0_CLIENT_ID,
    authorizationParams: { redirect_uri: window.location.origin },
    audience: config.AUTH0_AUDIENCE,
    onRedirectCallback,
  };

  return (
    <Auth0Provider {...providerConfig} >
      <AxiosInterceptorProvider>
        <CommonAuthProvider>
          {children}
        </CommonAuthProvider>
      </AxiosInterceptorProvider>
    </Auth0Provider>
  );
};

export { useAuth, Auth0ProviderWithHistory }
