import { useState } from "react";
import PropTypes from "prop-types";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { Grid, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import UsersApi from "../../api/users-api";
import ContactMailIcon from '@mui/icons-material/ContactMail';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import SwitchesGroup from "../switch-group";
import { pink } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { getConfig } from '../../config';

const appConfig = getConfig();

function RowRadioButtonsGroup({ elements, onChange, label }) {

  function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const [selectedValue, setSelectedValue] = useState(elements.length > 0 ? elements[0] : "");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    onChange(event.target.value);
  };

  return (
    <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label">{label}</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >

        {elements.map((el, index) => (
          <FormControlLabel
            key={index}
            checked={selectedValue === el}
            value={el}
            control={<Radio />}
            onChange={handleChange}
            label={capitalize(el)}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

RowRadioButtonsGroup.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
};

RowRadioButtonsGroup.defaultProps = {
  label: 'Object'
};

const Users = () => {
  const defaultMessageText = "Operation Success!"
  const defaultMessageSeverity = "success"
  const [loading, setLoading] = useState(false);
  const [ticketNumber, setTicketNumber] = useState("");
  const [currentEmail, setCurrentEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [errorTicket, setErrorTicket] = useState(false);
  const [errorCurrentEmail, setErrorCurrentEmail] = useState(false);
  const [errorNewEmail, setErrorNewEmail] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [messageSeverity, setMessageSeverity] = useState(defaultMessageSeverity);
  const [messageText, setMessageText] = useState(defaultMessageText);
  const [action, setAction] = useState("update");
  const [openConfirm, setOpenConfirm] = useState(false);

  let environmentList = ['DEV', 'UAT'];
  if (appConfig.BRANCH === "main") {
    environmentList = ['PROD'];
  }

  const [selectedEnvironment, setSelectedEnvironment] = useState(environmentList[0]);

  const onTicketTextFieldChange = (value) => {
    setErrorTicket(false)
    setTicketNumber(value)
  }

  const onCurrentEmailTextFieldChange = (value) => {
    setErrorCurrentEmail(false)
    setCurrentEmail(value)
  }

  const onNewEmailTextFieldChange = (value) => {
    setErrorNewEmail(false)
    setNewEmail(value)
  }

  const onSubmit = () => {
    setMessageSeverity("success")
    if (!currentEmail) {
      setErrorCurrentEmail(true);
      return;
    }
    if (!ticketNumber) {
      setErrorTicket(true);
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(currentEmail)) {
      setErrorCurrentEmail(true);
      setMessageSeverity("error")
      setMessageText("The current email is not a valid email address")
      setShowMessage(true)
      return;
    }

    if (action === "update") {
      if (!newEmail) {
        setErrorNewEmail(true);
        return;
      }

      if (!emailRegex.test(newEmail)) {
        setErrorNewEmail(true);
        setMessageSeverity("error")
        setMessageText("The new email is not a valid email address")
        setShowMessage(true)
        return;
      }

      if (newEmail === currentEmail) {
        setMessageSeverity("error")
        setMessageText("The new email is the same as the current email")
        setShowMessage(true)
        return;
      }
      // Call the update api here
      updateUser();
    } else {
      setOpenConfirm(true);
    }
  }

  const updateUser = () => {
    setLoading(true);
    setMessageText(defaultMessageText)
    setMessageSeverity(defaultMessageSeverity)
    UsersApi.update(selectedEnvironment, currentEmail, newEmail, ticketNumber).then((response) => {
      console.log(response)
    }).catch((e) => {
      console.log(e)
      setMessageSeverity("error")
      setMessageText(e.message)
    }).finally(() => {
      setLoading(false)
      setShowMessage(true)
    })
  }

  const deleteUser = () => {
    console.log("delete user");
    setOpenConfirm(false);
    setLoading(true);
    setMessageText(defaultMessageText)
    setMessageSeverity(defaultMessageSeverity)
    UsersApi.delete(selectedEnvironment, currentEmail, ticketNumber).then((response) => {
      console.log(response)
    }).catch((e) => {
      console.log(e)
      setMessageSeverity("error")
      setMessageText(e.message)
    }).finally(() => {
      setLoading(false)
      setShowMessage(true)
    })
  }

  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowMessage(false);
  };

  return (
    <div className="flex-1">
      <Snackbar
        open={showMessage}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
        onClose={handleCloseMessage}
      >
        <Alert
          severity={messageSeverity}
          sx={{ width: '100%' }}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseMessage}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          {messageText}
        </Alert>
      </Snackbar>
      <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
        <div className="min-w-0 flex-1">
          <div className="rounded-md bg-blue-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <InformationCircleIcon
                  className="h-5 w-5 text-blue-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium">Instructions:</h3>
                <div className="mt-2 text-sm">
                  <ul className="list-disc space-y-1 pl-5">
                    <li>Select the environment</li>
                    <li>Select the action</li>
                    <li>You need to provide the ticket number, current email and if the action is update the new email address</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 px-4 sm:px-6 lg:px-8">
        <Grid container spacing={2}>
          <Grid container xs={12} md={6}>
            <Grid item xs={12} md={12}>
              <SwitchesGroup options={environmentList} selectedOption={selectedEnvironment} onChange={setSelectedEnvironment} />
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl  >
                <FormLabel id="action-row-radio-buttons-group-label" className='text-center'>Action</FormLabel>
                <RadioGroup
                  row
                  value={action}
                  onChange={(e) => { setAction(e.target.value) }}
                >
                  <FormControlLabel value="update" control={<Radio />} label="Update" />
                  <FormControlLabel
                    value="delete"
                    sx={{
                      color: pink[800],
                      '&.Mui-checked': {
                        color: pink[600],
                      },
                    }}
                    control={<Radio sx={{
                      color: pink[800],
                      '&.Mui-checked': {
                        color: pink[600],
                      },
                    }} />}
                    label="Delete" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              className="!mb-2 !pb-2"
              type="search"
              error={errorTicket}
              id="outlined-basic"
              label="Ticket number"
              helperText="Ex: BDT-1234"
              variant="outlined"
              fullWidth={true}
              onChange={(e) => onTicketTextFieldChange(e.target.value)}
            />

            <TextField
              className="!mb-2 !pb-2"
              type="search"
              error={errorCurrentEmail}
              id="outlined-basic"
              label="Currrent Email"
              variant="outlined"
              fullWidth={true}
              onChange={(e) => onCurrentEmailTextFieldChange(e.target.value)}
            />
            <TextField
              className="!mt-2 !pt-2"
              type="search"
              error={errorNewEmail}
              id="outlined-basic"
              label="New Email"
              helperText="The new email to update"
              variant="outlined"
              fullWidth={true}
              disabled={action === "delete"}
              onChange={(e) => onNewEmailTextFieldChange(e.target.value)}
            />
            <div className="mt-4 pt-4">
              <LoadingButton
                color={
                  action === "delete" ?
                    "error"
                    :
                    "primary"
                }
                loading={loading}
                variant="contained"
                startIcon={
                  action === "delete" ?
                    <PersonRemoveIcon />
                    :
                    <ContactMailIcon />
                }
                onClick={onSubmit}
              >
                {action}
              </LoadingButton>
            </div>
          </Grid>
        </Grid>
      </div>
      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
      >
        <DialogTitle className="bg-red-600 text-gray-100">
          Are you sure you want to delete this user?
        </DialogTitle>
        <DialogContent>
          <DialogContentText className="text-justify mt-4 pt-4" >
            This action cannot be undone. This will permanently delete the user from the selected environment.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setOpenConfirm(false)} variant="contained">
            Cancel
          </Button>
          <Button onClick={deleteUser} color="error" variant="contained" >Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Users;
