import axios from "axios";

const base = 'users'

export const UsersApi = {

    update: async (environment, oldEmail, newEmail, ticket)=>{
        return await axios.put(`${base}/${environment.toLowerCase()}/${oldEmail}/${newEmail}/${ticket}`).then(response => response.data)
    },
    delete: async (environment, email, ticket)=>{
        return await axios.delete(`${base}/${environment.toLowerCase()}/${email}/${ticket}`).then(response => response.data)
    },
}

export default UsersApi;