import Users from "../components/pages/users";

const UsersPage = () => {
  
  return (
    <>
      <Users />
    </>
  );
};

export default UsersPage;
