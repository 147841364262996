import Salesforce from "../components/pages/salesforce-sync";

const SalesforceSyncPage = () => {
  
  return (
    <>
      <Salesforce />
    </>
  );
};

export default SalesforceSyncPage;
